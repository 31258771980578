import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Form.css'; // import ไฟล์ CSS ที่เราจะสร้าง

function Form() {
    const [sheetId, setSheetId] = useState('');
    const [sheetRange, setSheetRange] = useState('');
    const [tableName, setTableName] = useState('');
    const [sheets, setSheets] = useState([]);
    const [editingId, setEditingId] = useState(null);

    // ฟังก์ชันดึงข้อมูลชีตที่บันทึกไว้
    useEffect(() => {
        fetchSheets();
    }, []);

    const fetchSheets = async () => {
        try {
            const response = await axios.get('http://localhost:3000/api/sheets');
            setSheets(response.data);  // เก็บข้อมูลชีตใน state
        } catch (error) {
            console.error('Error fetching sheets:', error);
        }
    };

    // ฟังก์ชันบันทึกข้อมูลชีตใหม่
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editingId) {
                // ถ้าเป็นการแก้ไขข้อมูล
                await axios.put(`http://localhost:3000/api/sheets/${editingId}`, {
                    sheetId, sheetRange, tableName
                });
                setEditingId(null);
            } else {
                // ถ้าเป็นการบันทึกใหม่
                await axios.post('http://localhost:3000/api/sheets', {
                    sheetId, sheetRange, tableName
                });
            }

            setSheetId('');
            setSheetRange('');
            setTableName('');
            fetchSheets();  // ดึงข้อมูลใหม่หลังจากบันทึกสำเร็จ
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    // ฟังก์ชันลบข้อมูลชีต
    const handleDelete = async (id) => {
        try {
            await axios.delete(`http://localhost:3000/api/sheets/${id}`);
            fetchSheets();  // ดึงข้อมูลใหม่หลังจากลบสำเร็จ
        } catch (error) {
            console.error('Error deleting data:', error);
        }
    };

    // ฟังก์ชันแก้ไขข้อมูลชีต
    const handleEdit = (sheet) => {
        setSheetId(sheet.sheet_id);
        setSheetRange(sheet.sheet_range);
        setTableName(sheet.table_name);
        setEditingId(sheet.id);  // เก็บ ID ที่ต้องการแก้ไข
    };

    return (
        <div className="form-container">
            <h1 className="form-title">Google Sheets Management</h1>
            <form className="form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Google Sheets ID:</label>
                    <input 
                        type="text" 
                        value={sheetId} 
                        onChange={(e) => setSheetId(e.target.value)} 
                        required 
                    />
                </div>
                <div className="form-group">
                    <label>Sheet Range:</label>
                    <input 
                        type="text" 
                        value={sheetRange} 
                        onChange={(e) => setSheetRange(e.target.value)} 
                        required 
                    />
                </div>
                <div className="form-group">
                    <label>Table Name:</label>
                    <input 
                        type="text" 
                        value={tableName} 
                        onChange={(e) => setTableName(e.target.value)} 
                        required 
                    />
                </div>
                <button className="submit-btn" type="submit">
                    {editingId ? 'Update Sheet' : 'Save Sheet'}
                </button>
            </form>

            <h2>Saved Sheets</h2>
            <ul className="sheet-list">
                {sheets.map(sheet => (
                    <li key={sheet.id} className="sheet-item">
                        {sheet.sheet_id} - {sheet.sheet_range} ({sheet.table_name})
                        <button className="edit-btn" onClick={() => handleEdit(sheet)}>Edit</button>
                        <button className="delete-btn" onClick={() => handleDelete(sheet.id)}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Form;
