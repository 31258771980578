import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Dashboard.css'; // import ไฟล์ CSS ที่เราจะสร้าง

function Dashboard() {
    const [dataCounts, setDataCounts] = useState([]);

    useEffect(() => {
        async function fetchDataCounts() {
            const response = await axios.get('http://localhost:3000/api/data-counts');
            setDataCounts(response.data);
        }
        fetchDataCounts();
    }, []);

    return (
        <div className="dashboard-container">
            <h1 className="dashboard-title">Data Record Counts</h1>
            <div className="dashboard-grid">
                {dataCounts.map(table => (
                    <div key={table.name} className="dashboard-card">
                        <h2 className="dashboard-card-title">{table.name}</h2>
                        <p className="dashboard-card-count">Records: {table.count}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Dashboard;
